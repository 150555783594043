/* Dans votre fichier CSS */
@import url('https://cdn.jsdelivr.net/npm/icofont@1.0.0/icofont.min.css');

.navbar-dark .navbar-brand,
.navbar-dark .nav-link,
.navbar-dark .navbar-toggler-icon {
    color: #000 !important; /* Couleur noire pour le texte et les icônes de la navbar */
}

.navbar-transparent {
    background-color: rgba(255, 255, 255, 0.5) !important;
}

.navbar-toggler {
    border: none; /* Enlève la bordure par défaut */
  }
  
  .navbar-toggler-icon {
    background-color: #5c67f2;
    border: 1px solid white; /* Optionnel : ajouter une bordure blanche pour plus de visibilité */
  }