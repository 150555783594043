/* EventbriteApiModal.css */
.modal-content {
    border-radius: 10px; /* Coins arrondis pour le modal */
}

.modal-title {
    margin-bottom: 20px; /* Espacement en bas du titre */
}

.list-group-item {
    cursor: pointer; /* Change le curseur au survol */
}

.list-group-item:hover {
    background-color: #f8f9fa; /* Couleur d'arrière-plan au survol */
}

.form-control {
    border-radius: 5px; /* Coins arrondis pour les champs de formulaire */
}

/* Styles pour les boutons */
/* Styles pour les boutons à l'intérieur de .modal-content */
.modal-content .btn {
    border-radius: 0.375rem; /* Coins arrondis pour les boutons */
    font-size: 14px; /* Réduit la taille de la police */
    transition: background-color 0.3s, color 0.3s; /* Transition pour un effet doux */
    width: auto; /* Réduisez la largeur à auto pour ajuster la taille des boutons */
    padding: 8px 16px; /* Ajustement du padding pour une meilleure apparence */
    margin: 5px; /* Ajoutez un espacement entre les boutons */
}

/* Couleurs personnalisées pour les boutons */
.modal-content .btn-primary {
    background-color: #007bff; /* Couleur principale */
    border: none; /* Suppression de la bordure */
}

.modal-content .btn-secondary {
    background-color: #6c757d; /* Couleur secondaire */
    border: none; /* Suppression de la bordure */
}

/* Effet au survol des boutons */
.modal-content .btn-primary:hover {
    background-color: #0056b3; /* Couleur au survol */
}

.modal-content .btn-secondary:hover {
    background-color: #5a6268; /* Couleur au survol */
}

