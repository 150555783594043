/* Global Styles */
body {
    font-family: 'Arial', sans-serif;
    background-color: #f8f9fa;
    color: #343a40;
    margin: 0;
    padding: 0;
  }
  
  /* Container */
  .container {
    max-width: 900px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Company Overview */
  .company-overview {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .company-overview h1 {
    font-size: 2.5rem;
    color: #007bff;
    margin-bottom: 20px;
  }
  
  .company-overview p {
    font-size: 1.125rem;
    line-height: 1.6;
    color: #555;
    margin: 0 auto;
    max-width: 700px;
  }
  
  /* Card Styles */
  .card {
    border: 1px solid #007bff;
    border-radius: 8px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    margin-bottom: 20px;
  }
  
  .card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .card-header {
    background-color: #007bff;
    color: #0056b3;
    padding: 15px;
    border-bottom: 1px solid #0056b3;
    border-radius: 8px 8px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .bi-envelope {
    color: #0056b3;
    font-size: 35px
  }
  
  .card-header i {
    font-size: 1.6rem;
  }
  
  .card-title {
    font-size: 1.5rem;
    margin: 0;
  }
  
  /* Card Body */
  .card-body {
    padding: 20px;
  }
  
  .card-price {
    font-size: 1.2rem;
    font-weight: bold;
    color: #007bff;
    margin-bottom: 15px;
  }
  
  .card-feature {
    font-size: 1rem;
    line-height: 1.5;
    color: #555;
    margin-bottom: 10px;
  }
  
  .card-feature:last-child {
    margin-bottom: 0;
  }
  
  .btn-dark {
    background-color: #343a40;
    color: #fff;
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .btn-dark:hover {
    background-color: #495057;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .company-overview h1 {
      font-size: 2rem;
    }
  
    .company-overview p {
      font-size: 1rem;
    }
  
    .card-title {
      font-size: 1.3rem;
    }
  
    .card-header i {
      font-size: 1.4rem;
    }
  
    .btn-dark {
      padding: 8px 16px;
      font-size: 0.9rem;
    }
  }
  