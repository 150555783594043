/* Conteneur principal */
.app-container {
  display: flex;
  min-height: 100vh;
  background-image: url("https://media.licdn.com/dms/image/v2/D5622AQFT2fDFvce8Og/feedshare-shrink_800/feedshare-shrink_800/0/1706888948666?e=1740614400&v=beta&t=nhDV2h1xEZ1_FS27Y1vKR8dJRWyFGKab0ssT5OQ2M7M");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-family: 'Arial', sans-serif;
}

/* Barre latérale */
.sidebar {
  width: 300px;
  background-color: #5ba826; /* Vert plus foncé pour un look plus professionnel */
  color: white;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  border-radius: 0.5rem;
}

.sidebar-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  text-align: center;
  border-bottom: 2px solid white;
  padding-bottom: 0.5rem;
}

.sidebar-form .form-group {
  margin-bottom: 1.5rem;
}

.sidebar-form label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.input-field {
  width: 100%;
  padding: 0.8rem;
  border: 1px solid #ddd;
  border-radius: 0.3rem;
  font-size: 1rem;
  background-color: #f9f9f9;
}

.input-field:focus {
  outline: none;
  border-color: #5ba826;
  box-shadow: 0 0 4px rgba(91, 168, 38, 0.5);
}

.submit-button {
  background-color: white;
  color: #5ba826;
  padding: 0.7rem 1rem;
  border: 1px solid #5ba826;
  border-radius: 0.3rem;
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}

.submit-button:hover {
  background-color: #f0f0f0;
  color: #41701a;
  border-color: #41701a;
}

/* Contenu principal */
.main-content {
  flex-grow: 1;
  padding: 2rem;
}

.main-title {
  font-size: 2.5rem;
  font-weight: bold;
  color: #ffffff;
  margin-bottom: 2.5rem;
  text-align: center;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.content-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

/* Section tableau */
.table-section {
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.8rem;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.hotel-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.95rem;
}

.hotel-table th,
.hotel-table td {
  border: 1px solid #ddd;
  padding: 1rem;
  text-align: left;
}

.hotel-table th {
  background-color: #5ba826; /* Vert foncé */
  color: white;
  text-transform: uppercase;
}

.hotel-table td {
  background-color: #fafafa;
}

.hotel-table td img {
  width: 80px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.hotel-table td img:hover {
  transform: scale(1.1);
}

/* Section graphique */
.graph-section {
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.8rem;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  text-align: center;
}

/* Modale */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  max-width: 90%;
  max-height: 90%;
  background: white;
  border-radius: 0.8rem;
  overflow: hidden;
}

.modal-image {
  width: 100%;
  height: auto;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 2rem;
  color: black;
  cursor: pointer;
  font-weight: bold;
}

.modal-close:hover {
  color: red;
}

/* Section du tableau de bord */
.dashboard-section {
  background-color: #f9f9f9;
  padding: 1.5rem;
  margin-top: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.filter-input,
.filter-select {
  width: 100%;
  padding: 0.3rem;
  margin-top: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Réactions responsives */
@media (max-width: 1024px) {
  .app-container {
      flex-direction: column;
  }

  .sidebar {
      width: 100%;
      padding: 1rem;
      box-shadow: none;
      margin-bottom: 2rem;
  }

  .main-content {
      padding: 1rem;
  }

  .hotel-table td img {
      width: 70px;
      height: 50px;
  }

  .content-grid {
      grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      gap: 1.5rem;
  }
}

@media (max-width: 768px) {
  .sidebar {
      width: 100%;
      padding: 1rem;
  }

  .main-title {
      font-size: 2rem;
  }

  .hotel-table td img {
      width: 60px;
      height: 45px;
  }

  .content-grid {
      grid-template-columns: 1fr;
      gap: 1.5rem;
  }

  .hotel-table th,
  .hotel-table td {
      padding: 0.8rem;
  }
}

@media (max-width: 480px) {
  .main-title {
      font-size: 1.5rem;
      margin-bottom: 1.5rem;
  }

  .sidebar {
      padding: 1rem;
      margin-bottom: 1.5rem;
  }

  .hotel-table th,
  .hotel-table td {
      font-size: 0.85rem;
      padding: 0.6rem;
  }

  .hotel-table td img {
      width: 50px;
      height: 40px;
  }

  .submit-button {
      width: 100%;
  }
}
