.flight-form-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .flight-form-container h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .flight-form-container .form-group {
    margin-bottom: 15px;
  }
  
  .flight-form-container .form-group label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .flight-form-container .form-group input[type="text"],
  .flight-form-container .form-group input[type="date"],
  .flight-form-container .form-group input[type="number"],
  .flight-form-container .form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .flight-form-container .form-group input[type="radio"] {
    margin-right: 5px;
  }
  
  .flight-form-container .suggestions-list {
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    max-height: 150px;
    overflow-y: auto;
    position: absolute;
    width: calc(100% - 22px);
    z-index: 1000;
  }
  
  .flight-form-container .suggestions-list li {
    padding: 10px;
    cursor: pointer;
  }
  
  .flight-form-container .suggestions-list li:hover {
    background-color: #eee;
  }
  
  .flight-form-container button {
    width: 100%;
    padding: 10px;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  

  