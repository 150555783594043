/* Contact.css */

/* Conteneur principal du formulaire */
.container {
    max-width: 600px;
    margin: 0 auto; /* Centrer le formulaire */
    padding: 20px; /* Ajouter du padding autour du formulaire */
    background-color: #f8f9fa; /* Couleur de fond claire pour le formulaire */
    border-radius: 8px; /* Coins arrondis */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Ombre légère pour un effet de profondeur */
  }
  
  /* Espacement général des éléments du formulaire */
  .form-group {
    margin-bottom: 1.5rem; /* Espacement entre les groupes de champs */
  }
  
  /* Style pour les champs obligatoires */
  .required {
    color: red;
    font-weight: bold;
  }
  
  /* Style pour les descriptions sous les champs */
  .form-text {
    margin-top: 0.25rem; /* Espacement au-dessus de la description */
    margin-bottom: 1rem; /* Espacement en dessous de la description */
    font-size: 0.875rem; /* Taille de police plus petite pour la description */
    color: #6c757d; /* Couleur du texte pour la description */
  }
  
  /* Espacement et style pour le bouton d'envoi */
  .btn-primary {
    margin-top: 1rem; /* Espacement au-dessus du bouton */
    padding: 0.75rem 1.25rem; /* Padding intérieur du bouton */
    font-size: 1rem; /* Taille de police du bouton */
  }
  
  /* Style pour le champ de fichier */
  .form-control-file {
    margin-top: 0.5rem; /* Espacement au-dessus du champ de fichier */
    padding: 0.5rem; /* Padding intérieur du champ de fichier */
    border: 1px solid #ced4da; /* Bordure légère pour le champ de fichier */
    border-radius: 4px; /* Coins arrondis pour le champ de fichier */
  }
  