/* General Resets */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  color: #333;
}

/* Container for the entire flow list */
.flows-container {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px; /* Rounded corners for a modern look */
}

/* Search and select inputs styling */
.search-input, .language-select, .category-select, .country-select {
  padding: 12px;
  margin: 10px 0;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle inner shadow for better depth */
}

/* Flow item card */
.flow-item {
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.flow-logo {
  width: 120px;
  height: auto;
  margin-right: 20px;
}

.flow-details {
  flex: 1;
}

.flow-details h2 {
  margin-top: 0;
}

button {
  padding: 10px 20px;
  color: white;
  background-color: #5c67f2;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #4a54e1;
}

.btn-disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Confirmation dialog styling */
.confirmation-dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 80%; /* Adjust width to fit smaller screens */
}

.confirmation-dialog-content button {
  margin: 5px;
  padding: 8px 15px;
}


/* Loading state */
.loading {
  text-align: center;
  padding: 50px 0;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .flows-container {
    padding: 15px;
  }

  .flow-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .flow-logo {
    width: 100px;
    margin-bottom: 15px;
  }

  .flow-details {
    text-align: left;
  }

  .search-input, .language-select, .category-select, .country-select {
    margin: 10px 0;
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .flows-container {
    padding: 10px;
  }

  .search-input, .language-select, .category-select, .country-select {
    margin: 5px 0;
    padding: 8px;
  }

  .flow-item {
    padding: 10px;
  }

  .flow-logo {
    width: 80px;
  }

  .confirmation-dialog {
    width: 90%;
    padding: 15px;
  }
}
/* Styles pour le conteneur de modaux */
.modal {
  position: fixed;
  left: 0;
  width: 100%;
  height: calc(100% - var(--navbar-height, 0)); /* Ajuster la hauteur pour compenser la navbar */
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Assurez-vous que le modal commence après la navbar */
  z-index: 1000;
  overflow: auto;
}

/* Styles pour la fenêtre du modal */
.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  max-width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px; /* Ajuste le margin-top si nécessaire */
  position: relative; /* Assurez-vous que le bouton de fermeture se positionne correctement */
}

/* Styles pour le bouton de fermeture du modal */
.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

/* Styles pour la boîte de confirmation */
.confirmation-dialog {
  position: fixed;
  left: 0;
  width: 100%;
  height: calc(100% - var(--navbar-height, 0)); /* Ajuster la hauteur pour compenser la navbar */
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirmation-dialog-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}
