body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 20px;
    padding: 20px;
}
h1, h2, h3 {
    color: #333;
}
p {
    color: #555;
}
ul {
    margin-top: 10px;
}