/* Container principal du footer */
.footerContainer {
  background-color: #f8f9fa; /* Couleur claire pour un contraste doux */
  padding: 30px 15px;
  text-align: center;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); /* Ajoute un petit ombrage pour séparer du contenu */
  font-family: 'Arial', sans-serif;
}

/* Icônes sociales */
.socialIcons a {
  margin: 0 15px;
  color: #007bff; /* Bleu classique */
  font-size: 1.5rem;
  transition: color 0.3s ease, transform 0.3s ease;
}

.socialIcons a:hover {
  color: #0056b3; /* Un bleu plus foncé au survol */
  transform: translateY(-3px); /* Effet léger de survol */
}

/* Navigation du footer */
.footerNav ul {
  list-style: none;
  padding: 0;
  margin: 20px 0;
}

.footerNav li {
  display: inline-block;
  margin: 0 15px;
}

.footerNav a {
  text-decoration: none;
  color: #333;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s ease, text-decoration 0.3s ease;
}

.footerNav a:hover {
  color: #5ba826; /* Vert accent au survol */
  text-decoration: underline;
}

/* Section du bas du footer */
.footerBottom {
  margin-top: 20px;
  font-size: 0.9rem;
  color: #555; /* Gris foncé pour le texte copyright */
}

.footerBottom .designer {
  font-weight: bold;
  color: #007bff; /* Bleu pour le nom du designer */
}

/* Responsivité */
@media (max-width: 768px) {
  .socialIcons {
    margin-bottom: 20px;
  }

  .socialIcons a {
    margin: 0 12px;
  }

  .footerNav ul {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .footerNav li {
    display: block;
  }
}

@media (max-width: 480px) {
  .footerContainer {
    padding: 20px 10px;
  }

  .socialIcons a {
    font-size: 1.3rem; /* Réduit la taille des icônes sur mobile */
  }

  .footerNav a {
    font-size: 0.9rem;
  }

  .footerBottom {
    font-size: 0.8rem;
  }
}
