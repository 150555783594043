/* src/pages/Profile.css */
.profile-container {
    max-width: 800px;
    margin: auto;
    padding: 20px;
  }
  
  .profile-info {
    margin-bottom: 20px;
  }
  
  .profile-item {
    margin-bottom: 10px;
  }
  
  .profile-item label {
    display: block;
    font-weight: bold;
  }
  
  .profile-item span, .profile-item input {
    display: block;
    margin-top: 5px;
  }
  
  .profile-item input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  .profile-actions {
    text-align: center;
  }
  
  .profile-actions button {
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
  }
  